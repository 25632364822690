import axios from 'axios'

function getSubdomain() {
  return window.location.host.split('.')[0]
}

export const axiosInstance = axios.create({
  baseURL: `//${getSubdomain()}.${
    process.env.VUE_APP_NAJA_API_HOST
  }/naja-api/v1`,
  transformRequest: [
    function(data, headers) {
      headers['Naja-Api-Key'] = process.env.VUE_APP_NAJA_API_ACCESS_KEY
      return JSON.stringify(data)
    },
  ],
  headers: { 'Content-Type': 'application/json' },
})

export const endpoints = {
  authenticate: '/authenticate',
  resetPasswordInstructions: '/reset-password-instructions',
  resetPassword: '/reset-password',
  organizationInfo: '/organizations/info',
  loginSlides: '/login_slides',
}

export default {
  baseURL: axiosInstance.defaults.baseURL,
  accessToken: process.env.VUE_APP_NAJA_API_ACCESS_KEY,
  authenticate: credentials =>
    axiosInstance.post(endpoints.authenticate, credentials),
  resetPasswordInstructions: data =>
    axiosInstance.post(endpoints.resetPasswordInstructions, data),
  resetPassword: data => axiosInstance.post(endpoints.resetPassword, data),
  getOrganizationInfo: () => axiosInstance.get(endpoints.organizationInfo),
  loginSlides: () => axiosInstance.get(endpoints.loginSlides),
}
